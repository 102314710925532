.hero_container {
  background-image: url("/images/home-hero.jpeg");
  position: relative;

  > div{
    position: relative;
    z-index: 2;
  }

  &::before{
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0 0 0 / 25%);
  }

  .header_text,
  .text {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-style: normal;
  }

  .header_text {
    font-size: 5.25rem;
    line-height: 110%;
    max-width: 1024px;
  }

  .text {
    font-size: 1.5rem;
    line-height: 120%; /* 28.8px */
  }
}


@media only screen and (max-width: 1024px) {
  .hero_container{
   
    .header_text{ 
      font-size: 3.5rem;
    }
  }
  
}

@media only screen and (max-width: 640px) {
  .hero_container{
    height: 480px;
    .header_text{ 
      font-size: 2.5rem;
      line-height: 1.2;
      width: 100%;
    }

    .text{ 
      font-size: 16px ;
    }
  }
  
}
