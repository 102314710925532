.container {
  background-color: #151515;
  padding: 65px 49.116px 24px 49.35px;
  z-index: 99;
  position: relative;

  .logo_text {
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 103.571%;
    color: #e8e8e8;
  }

  .group_label,
  .item > a {
    font-style: normal;
    line-height: normal;
    font-weight: 400;
  }

  .group_label {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1.625rem;
  }

  .item {
    a {
      color: #b6b6b6;
      font-size: 16px;
      margin-bottom: 1.1rem;
    }
    a:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .copyright {
    p {
      color: #b6b6b6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .some_bt {
    button {
      background: #414141;
      padding: 12px;
      transition: 0.5s;

      &:hover {
        opacity: 0.5;
        transition: 0.5s;
      }
    }
  }
}

@media only screen and (max-width: 1490px) {
  footer.container {
    .item_logo {
      width: 53%;
    }

    .item_menu {
      width: 47%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  footer.container {
    .item_logo {
      width: 40%;
    }

    .item_menu {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 768px) {
  footer.container {
    padding: 60px 20px 40px 20px;

    .item_logo {
      width: 100%;
      padding-bottom: 50px;
      justify-content: space-between;
      align-items: center;
    }

    .item_menu {
      width: 100%;
    }

    .copyright {
      margin-top: 25px;
      .some_bt {
        justify-content: flex-start;
        padding-top: 25px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  footer.container {
    .menu_wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px 10px;

      li:last-child {
        padding-bottom: 0;
      }

      > div {
        width: 100%;
      }
    }
    .copyright {
      p {
        font-size: 14px;
      }
    }

    .some_bt {
      button {
        padding: 8px;
      }
    }
  }
}
