.features_container {
  background-color: #f4f3ec;

  .resource_heading {
    font-size: 48px;
    line-height: 120%; /* 62.1px */
  }
  .resource_text {
    line-height: 133%; /* 28.8px */
    color: var(--Grey2);
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .features_container {
    .resource_heading {
      font-size: 2.5rem;
    }
    .resource_text {
      font-size: 16px;
    }
  }
}
