.hero_container {
  // background-image: url("/images/home-hero.jpeg");
  // position: relative;

  > div {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0 0 0 / 25%);
  }

  .header_text,
  .text {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-style: normal;
  }

  .header_text {
    font-size: 5.25rem;
    line-height: 110%;
  }
}
