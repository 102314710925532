.header_container {
  max-width: 740px;
  border-radius: 8px;

  .logo_text {
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 103.571%;
  }

  ul {
    li {
      font-size: 16px;
    }
  }

  .item {
    font-size: 16px;
    button {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

@media only screen and (max-width: 991px) {
  .header_container {
    max-width: 95%;
    padding-right: 5.2rem;

      svg{
        width: 160px;
      }
    
  }
}

@media only screen and (max-width: 640px) {
  // Hamburger Button
  .hamburger_btn {
    .hamburger_box {
      width: 40px;
      height: 40px;
      display: flex;
      position: relative;
      align-items: center;
      opacity: 1;
      transition: 0.3s;
      margin-right: -6px;

      span {
        width: 25px;
        height: 2px;
        background-color: #141b34;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        left: 8px;
        border-radius: 20px;

        &.top {
          transform: translateY(-3px);
        }

        &.bottom {
          transform: translateY(5px);
        }
      }
    }

    &.activated {
      .top {
        transform: rotatez(45deg) translate(1px, 1px) !important;
        width: 20px;
      }

      .bottom {
        transform: rotatez(-45deg) translate(-1px, 1px) !important;
        width: 20px;
      }
    }
  }

  .header_container {
    max-width: 100%;
    border-radius: 0;
    padding-right: 1.5rem;
  }

  // Nav menu
  .nav_container {
    position: absolute;
    width: 100%;
    background: #fff;
    inset: 0;
    padding: 20px;
    height: 400px;
    justify-content: center;
    align-items: flex-start;
    padding-top: 70px;
    top: 66px;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-15px);

    ul {
      display: flex;
      text-align: center;
      gap: 28px;
      flex-direction: column;

      li {
        font-size: 24px;
      }

      button {
        position: absolute;
        bottom: 24px;
        left: 0;
        right: 0;
        width: 90%;
        margin: auto;
      }
    }
  }

  .activated + .nav_container {
    opacity: 1;
    pointer-events: all;
    transition: 0.5s;
    transform: translateY(0px);
  }
}
