.vision_promises_container {
  background-color: #f4f3ec;

  .vision_text {
    color: #767676;

    /* h1 */
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
  }
  .promise_text {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.1;
    text-align: left;
  }

  .card_button {
    display: flex;
    width: 109px;
    height: 35px;
    padding: 0.5rem 1rem;
    border-radius: 40px;
    background: #009e4c;
  }

  .card_header,
  .card_description {
    font-style: normal;
    font-weight: 400;
  }
  .card_header {
    font-size: 2.25rem;
    line-height: normal;

  }
  .card_description {
    line-height: normal;
    color: #3E3E3E;
  }
}


@media only screen and (max-width: 860px) {
  .vision_promises_container {
    .vision_text, .promise_text{
      font-size: 2.5rem;
    }

    .card_header{
      font-size: 24px;
    }

    .card_button{
      height: 30px;
    }
  }
  

}

@media only screen and (max-width: 640px) {
  
}