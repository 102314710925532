.partners_container {
  background-color: #f4f3ec;
  .header_text {
    color: var(--Foundation-Grey-Normal);
    font-size: 3.375rem;
    font-weight: 400;
  }

  .logo_list img[alt="created by black"] {
    height: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .logo_list {
    display: none;
  }

  .for_mobile {
    .logo_list {
      display: flex;
      gap: 0;
      padding: 10px 0;

      > div {
        margin: 0 10px;
      }
    }
    .mobile_link {
      margin: 0 10px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .partners_container {
    .header_text {
      font-size: 2.5rem;
    }
  }
}
