.privacy_container {
  .page_header {
    line-height: 115%; /* 62.1px */
  }

  .policy_wrapper {
    .policy_header {
      line-height: 130%; /* 38.4px */
    }
    .policy_text {
      color: #626262;
      line-height: 150%; /* 36px */
    }
  }
}

@media only screen and (max-width: 640px) {
  .privacy_container {
    h2 {
      font-size: 2.5rem;
    }

    h3 {
      width: 100%;
    }
    p {
      font-size: 16px;
    }
    li {
      font-size: 16px;
    }
  }
}
