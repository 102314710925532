.vision {
  background-color: #f4f3ec;
  .vision_text{
    font-weight: 400;
  }
}



@media only screen and (max-width: 640px) {
  .vision{
    .vision_text{
      font-size: 2.5rem;
    }
  }
}